// File: src/components/ReusableLink.tsx
import React from 'react';
import { Link } from 'gatsby';
import DropDown from './DropDown';

type ReusableLinkProps = {
  to: string;
  children: React.ReactNode;
};

export const ReusableLink: React.FC<ReusableLinkProps> = ({ to, children }) => {
  return (
    <Link
      to={to}
      activeStyle={{ color: '#109CDC' }}
      className="text-[1.45rem] font-[700] text-base-black no-underline lg:text-[1.4rem] 2xl:text-[1.5rem]"
    >
      {children}
    </Link>
  );
};

const NavLink: React.FC = () => {
  return (
    <div className="flex w-[98%] justify-center gap-10 text-center">
      <ReusableLink to="/">Home</ReusableLink>
      <ReusableLink to="/about-us">About Us</ReusableLink>
      <DropDown>
        <ReusableLink to="/about-diabetes">About Diabetes</ReusableLink>
      </DropDown>
      <ReusableLink to="/programs">Our Programs</ReusableLink>
      <ReusableLink to="/library">Library</ReusableLink>
      {/* <ReusableLink to="/pageNotFound">FAQ'S</ReusableLink> */}
      <ReusableLink to="/contact-us">Contact Us</ReusableLink>
    </div>
  );
};

export default NavLink;
