import React from 'react';
import BottomBar from './components/BottomBar';
import ContentBar from './components/ContentBar';

type Props = {};

const Footer = () => {
  return (
    <div>
      <div className="px-[6rem]">
        <ContentBar />
        <div className="h-[0.1rem] w-full bg-warning-orange"></div>
        <BottomBar />
      </div>
    </div>
  );
};

export default Footer;
