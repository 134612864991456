import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { ReusableLink } from '../components/NavLink';
import { Link } from 'gatsby';
import XlLogo from '../../logo/XlLogo';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import SmLogo from '../../logo/SmLogo';
import CustomLink from '../../CustomLink';
import { StaticImage } from 'gatsby-plugin-image';
import { FaPhone } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { Divider } from '@mui/material';

type Anchor = 'right';

export default function NavDrawer() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        font: '50rem',
        width: '60dvw',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '95%',
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <List>
          <div className="flex justify-between pl-3">
            <SmLogo />
            <Button onClick={toggleDrawer('right', false)}>
              <AiOutlineMenuUnfold
                fontSize={'4rem'}
                className="text-primary-blue"
              />
            </Button>
          </div>

          <div className="mx-auto flex w-[54%] flex-col justify-start gap-10 py-10 text-start">
            <ReusableLink to="/">Home</ReusableLink>

            <ReusableLink to="/about-us">About Us</ReusableLink>

            <ReusableLink to="/about-diabetes">About Diabetes</ReusableLink>

            <ReusableLink to="/programs">Our Programs</ReusableLink>

            <ReusableLink to="/library">Library</ReusableLink>

            {/* <ReusableLink to="/pageNotFound">FAQ'S</ReusableLink> */}

            <ReusableLink to="/contact-us">Contact Us</ReusableLink>
          </div>
        </List>
      </div>
      <div className="">
        <div className="mx-auto flex w-[80%] items-center justify-evenly rounded-[1rem] border border-[#E8E8E8] px-6 py-3">
          <div className="h-[2rem] w-[1rem]">
            <CustomLink
              url={'https://web.facebook.com/suwadiviyalk/?_rdc=1&_rdr'}
              label={
                <StaticImage
                  src={'../../../../images/socialMedia/facebook.png'}
                  alt={'faceBook'}
                  className="object-cover object-center"
                  placeholder="blurred"
                />
              }
            />
          </div>
          <div className="h-[1.7rem] w-[1.7rem]">
            <CustomLink
              url={'https://www.instagram.com/suwa_diviya/'}
              label={
                <StaticImage
                  src={'../../../../images/socialMedia/instagram.png'}
                  alt={'instagram'}
                  className="object-cover object-center"
                  placeholder="blurred"
                />
              }
            />
          </div>
          <div className="h-[1.3rem] w-[1.9rem]">
            <CustomLink
              url={'https://www.youtube.com/@SuwaDiviya'}
              label={
                <StaticImage
                  src={'../../../../images/socialMedia/youtube.png'}
                  alt={'youTube'}
                  className="object-cover object-center"
                  placeholder="blurred"
                />
              }
            />
          </div>
          <div className="h-[1.7rem] w-[1.7rem]">
            <CustomLink
              url={
                'https://www.linkedin.com/company/suwa-diviya/posts/?feedView=all'
              }
              label={
                <StaticImage
                  src={'../../../../images/socialMedia/linkedin.png'}
                  alt={'linkedin'}
                  className="object-cover object-center"
                  placeholder="blurred"
                />
              }
            />
          </div>
        </div>
        <div className="mt-[3rem] rounded-md bg-[#0196D9] px-[2rem]">
          <div className="flex flex-col">
            <div className="flex items-center gap-2 pt-5 text-warning-orange">
              <IoLocationSharp style={{ fontSize: '1.2rem' }} />
              <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
                139 Srimath Anagarika Dharmapala Mawatha
              </div>
            </div>
            <div className="flex items-center gap-2 py-5 text-warning-orange">
              <FaPhone style={{ fontSize: '1.2rem' }} />
              <CustomLink url="tel:+1234567890" label="+94 77 543 543" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div className="flex h-[8rem] items-center justify-between bg-base-white p-8 pr-0">
      <Link to="/">
        <XlLogo width="16rem" height="7rem" />
      </Link>
      <React.Fragment>
        <div className="flex w-full justify-end">
          <Button onClick={toggleDrawer('right', true)}>
            <AiOutlineMenuFold
              fontSize={'4rem'}
              className="text-primary-blue"
            />
          </Button>
        </div>
        <SwipeableDrawer
          anchor="right"
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
