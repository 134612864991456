import React from 'react';
import CustomLink from '../../CustomLink';
import Divider from './Divider';
import { BiLogoFacebook } from 'react-icons/bi';
import { FiInstagram } from 'react-icons/fi';
import { FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { FaPhone } from 'react-icons/fa6';
import { Link } from 'gatsby';

const NavBarHeader = () => {
  return (
    <div className="flex h-[4.2rem] items-center justify-between bg-primary-blue px-[5.1rem]">
      <div className="flex h-5 items-center gap-2">
        {/* <CustomLink url="" label="FREE Programs for Schools & Cooperates" /> */}
        <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
          FREE Programs for Schools & Cooperates
        </div>
        <Divider />
        {/* <CustomLink url="" label="Diabetes Downloads & Resources " /> */}
        <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
          Diabetes Downloads & Resources
        </div>
        <Divider />
        {/* <CustomLink url="" label="Join The Support Group" /> */}
        <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
          Join The Support Group
        </div>
      </div>
      <div className="flex gap-2">
        <div className="flex items-center gap-2 text-warning-orange">
          <IoLocationSharp style={{ fontSize: '1.2rem' }} />
          <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
            139 Srimath Anagarika Dharmapala Mawatha
          </div>
        </div>
        <Divider />
        <div className="flex items-center gap-2 text-warning-orange">
          <FaPhone style={{ fontSize: '1.2rem' }} />
          <CustomLink url="tel:+1234567890" label="+94 77 543 543" />
        </div>
        <Divider />

        <div className="flex gap-4 text-white">
          <Link to="https://web.facebook.com/suwadiviyalk/?_rdc=1&_rdr">
            <BiLogoFacebook
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
          <Link to="https://www.linkedin.com/company/suwa-diviya/posts/?feedView=all">
            <FaLinkedinIn
              style={{ fontSize: '1.1rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
          <Link to="https://www.instagram.com/suwa_diviya/">
            <FiInstagram
              style={{ fontSize: '1.1rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
          <Link to="https://www.youtube.com/@SuwaDiviya">
            <FaYoutube
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBarHeader;
