import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

type Props = {
  width?: string;
  height?: string;
  altText?: string;
};

const XlLogo = ({
  width = '23.4rem',
  height = '100%',
  altText = 'XL Logo',
}: Props) => {
  return (
    <div style={{ width, height }}>
      <StaticImage
        src="../../../images/xlLogo.svg"
        alt={altText}
        placeholder="blurred"
      />
    </div>
  );
};

export default XlLogo;
