import React, { useState } from 'react';
import XlLogo from '../../logo/XlLogo';
import { Link } from 'gatsby';
import CustomButton from '../../buttons/CustomButton';
import { MdEmail } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formIds } from '../../../../utils/formIds';
import { FaSpinner } from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';

type ReusableLinkProps = {
  to: string;
  children: React.ReactNode;
};
const ReusableLink: React.FC<ReusableLinkProps> = ({ to, children }) => {
  return (
    <Link
      to={to}
      // activeStyle={{ color: '#109CDC' }}
      className="text-left font-poppins text-[1.3rem] font-normal leading-[1.8rem] tracking-[0.02rem] text-[#5D5E76] no-underline"
    >
      {children}
    </Link>
  );
};

const ContentBar = () => {
  const [emailToSubscribe, setEmailToSubscribe] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // Handle form submission
      await axios.post(`${process.env.GATSBY_BACKEND_URL}/submit` || '', {
        formId: formIds.SUBSCRIBE_FORM,
        responses: {
          email: emailToSubscribe,
          name: 'N/A',
        },
      });
      toast.success('Subscribed successfully!');
    } catch {
      toast.error('Something went wrong, please try again later');
    }
    setEmailToSubscribe('');
    setIsSubmitting(false);
  };

  return (
    <div className="grid grid-cols-1 py-[3rem] sm:grid-cols-[6fr,4fr] sm:py-[6rem] lg:grid-cols-[6fr,6fr,4fr]">
      <div className="flex flex-col justify-start sm:mb-[5rem]">
        <div>
          <XlLogo width="20rem" />
        </div>
        <p className="mt-[4rem] w-full text-start font-poppins text-[1.4rem] font-normal leading-[3rem] tracking-wider text-[#585B63]">
          Suwa Diviya is dedicated to combating diabetes through a comprehensive
          approach that focuses on prevention, early diagnosis, and adequate
          control to prevent complications.
        </p>
      </div>
      <div className="my-[4rem] flex lg:my-0">
        <div className="mx-auto flex flex-col justify-center text-start">
          <h1 className="mb-[1.5rem] text-left font-poppins text-[1.6rem] font-semibold leading-[3rem] text-base-black">
            Overview
          </h1>
          <div className="flex flex-col gap-2">
            <ReusableLink to="/about-us">About Us</ReusableLink>
            <ReusableLink to="/about-diabetes">About Diabetes</ReusableLink>
            <ReusableLink to="/programs">Our Programs</ReusableLink>
            <ReusableLink to="/library">Media Hub</ReusableLink>
          </div>
        </div>
        <div className="mx-auto flex flex-col justify-center text-start">
          <h1 className="mb-[1.5rem] text-left font-poppins text-[1.6rem] font-semibold leading-[3rem] text-base-black">
            Support
          </h1>
          <div className="flex flex-col gap-2">
            <ReusableLink to="/contact-us">General FAQ’s</ReusableLink>
            <ReusableLink to="/about-us">Resources</ReusableLink>
            <ReusableLink to="/contact-us">Contact Us</ReusableLink>
            <ReusableLink to="/">Book a Free Program</ReusableLink>
          </div>
        </div>
      </div>

      <div className="flex h-1/2 items-center justify-center sm:col-span-full lg:col-auto">
        <form onSubmit={handleSubscribe}>
          <div className="flex w-[39.2rem] items-center justify-center rounded-lg border border-[#ECEFF3] p-[0.6rem]">
            <MdEmail color="#8588A6" fontSize={20} />
            <div className="ml-[1.2Rem] w-[21.5rem]">
              <input
                type="email"
                placeholder="Your Email"
                className="text-[1.2rem] outline-none"
                value={emailToSubscribe}
                onChange={(e) => setEmailToSubscribe(e.target.value)}
                required
              />
            </div>
            <CustomButton
              borderRadius="4px"
              width="12.1rem"
              height="3.2rem"
              backgroundColor="#FAA61A"
              disabled={!emailToSubscribe || isSubmitting}
              type="submit"
              children={
                // <p className="text-center font-poppins text-[1.2rem] font-medium leading-[2rem] tracking-[0.02rem] text-base-white">
                //   SUBSCRIBE
                // </p>
                <p className="flex items-center justify-center text-center font-poppins text-[1.2rem] font-medium leading-[2rem] tracking-[0.02rem] text-base-white">
                  {isSubmitting ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    'Register'
                  )}
                </p>
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentBar;
