import React from 'react';

const BottomBar = () => {
  const CurrentYear = new Date().getFullYear();
  return (
    <div className="flex flex-col items-center justify-between py-11 sm:flex-row">
      <div className="text-center font-poppins text-sm font-normal leading-[2.1rem] tracking-[0.1rem] text-[#5D5E76] sm:text-left">
        ©{CurrentYear} Copyright Suwa Diviya. All Rights Reserved.
      </div>
      <div className="mt-[2rem] flex gap-6 sm:mt-0 sm:gap-3">
        <div className="text-end font-poppins text-sm font-semibold leading-[2.1rem] tracking-[0.1rem] text-[#2E2E38] underline">
          Privacy Policy
        </div>
        <div className="text-end font-poppins text-sm font-semibold leading-[2.1rem] tracking-[0.1rem] text-[#2E2E38] underline">
          Terms of Use
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
