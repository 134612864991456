import React, { useState } from 'react';
import NavBarHeader from './components/NavBarHeader';
import NavLink from './components/NavLink';
import { Link } from 'gatsby';
import XlLogo from '../logo/XlLogo';
import ToggleLanguage from './components/ToggleLanguage';
import CustomButton from '../buttons/CustomButton';
import NavDrawer from './components/NavDrawer';
import BookFreeProgram from '../modals/BookFreeProgram';

const NavBar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div className="w-full">
      <div className="hidden lg:block">
        <NavBarHeader />
        <div className="flex h-[10rem] items-center border border-primary-gray bg-base-white px-[5.1rem]">
          <div className="flex items-center justify-center">
            <Link to="/" className="flex items-center">
              <XlLogo width="15rem" />
            </Link>
          </div>

          <div className="w-[10%]">{/* <ToggleLanguage /> */}</div>

          <div className="flex w-[80%] justify-center">
            <NavLink />
          </div>

          <div>
            <CustomButton
              onClick={handleClick}
              width="20rem"
              height="4.6rem"
              borderColor="#FAA61A66"
              borderRadius="high"
              children={
                <p className="text-sm font-[700] leading-4">
                  Book a{' '}
                  <span className="whitespace-nowrap text-sm text-light-blue">
                    {' '}
                    Free{' '}
                  </span>
                  Program
                </p>
              }
            />
          </div>
        </div>
      </div>
      <div className="h-[8rem] w-full items-center justify-between lg:hidden">
        <NavDrawer />
      </div>
      <BookFreeProgram open={open} setOpen={setOpen} />
    </div>
  );
};

export default NavBar;
