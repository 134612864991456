import React from 'react';

type Props = {
  url: string;
  label: string | number | React.ReactNode;
  className?: string;
};

const CustomLink = ({ url, label, className }: Props) => {
  return (
    <div>
      <a
        href={url}
        className={`flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline hover:text-light-blue ${className}`}
      >
        {label}
      </a>
    </div>
  );
};

export default CustomLink;
