import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

type Prop = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (e: boolean) => void;
};

const ModalWrapper = ({ children, open, setOpen }: Prop) => {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
            sx: {
              position: 'absolute',
              backdropFilter: 'blur(15px)',
            },
          },
        }}
      >
        <Fade in={open}>
          <div className="flex items-center justify-center">{children}</div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalWrapper;
