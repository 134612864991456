// Import global styles
import './src/styles/global.css';
import './src/styles/main.scss';

// Import third-party styles
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Carousel styles
import 'react-multi-carousel/lib/styles.css'; // Multi-carousel styles
import 'react-medium-image-zoom/dist/styles.css'; // Image zoom styles
import 'react-toastify/dist/ReactToastify.css'; // Toastify styles


// Import React and the context provider
import React from 'react';
import { SectionProvider } from './src/store/SectionContext';
import { ContentfulDataProvider } from './src/store/ContentfulDataContext';

import Layout from './src/components/layout/Layout';

//Location provider
import { LocationProvider } from '@reach/router';
// Wrap the root element with the ContentfulDataProvider context
export const wrapRootElement = ({ element }) => (
  <ContentfulDataProvider>
    <SectionProvider>
      <LocationProvider>
        <Layout>{element}</Layout>
      </LocationProvider>
    </SectionProvider>
  </ContentfulDataProvider>
);
