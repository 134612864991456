exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-diabetes-tsx": () => import("./../../../src/pages/about-diabetes.tsx" /* webpackChunkName: "component---src-pages-about-diabetes-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-bmi-tsx": () => import("./../../../src/pages/bmi.tsx" /* webpackChunkName: "component---src-pages-bmi-tsx" */),
  "component---src-pages-bmr-tsx": () => import("./../../../src/pages/bmr.tsx" /* webpackChunkName: "component---src-pages-bmr-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-drf-tsx": () => import("./../../../src/pages/drf.tsx" /* webpackChunkName: "component---src-pages-drf-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-library-tsx": () => import("./../../../src/pages/library.tsx" /* webpackChunkName: "component---src-pages-library-tsx" */),
  "component---src-pages-page-not-found-tsx": () => import("./../../../src/pages/pageNotFound.tsx" /* webpackChunkName: "component---src-pages-page-not-found-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-member-tsx": () => import("./../../../src/templates/member.tsx" /* webpackChunkName: "component---src-templates-member-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

