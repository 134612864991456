import React, { ReactNode, useEffect } from 'react';
import { useLocation } from '@reach/router';
import NavBar from '../common/navBar/NavBar';
import Footer from '../common/footer/Footer';

type Props = {
  children: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const location =
    typeof window !== 'undefined' ? useLocation() : { pathname: '/' };

  // Scroll to top on location change
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div>
      {/* Fixed NavBar at the top */}
      <div className="fixed top-0 z-[1000] w-full">
        <NavBar />
      </div>

      {/* Main content area with initial top margin */}
      <main className="mt-[8rem] sm:mt-[8.2rem] lg:mt-[14rem]">{children}</main>

      <Footer />
    </div>
  );
};

export default Layout;
