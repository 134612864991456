import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the type for the context value
interface SectionContextType {
  section: string;
  setSection: React.Dispatch<React.SetStateAction<string>>;
}

// Create the Context
const SectionContext = createContext<SectionContextType | undefined>(undefined);

// Create a provider component
interface SectionProviderProps {
  children: ReactNode;
}

export const SectionProvider: React.FC<SectionProviderProps> = ({
  children,
}) => {
  const [section, setSection] = useState<string>('Videos');

  return (
    <SectionContext.Provider value={{ section, setSection }}>
      {children}
    </SectionContext.Provider>
  );
};

// Custom hook to use the SectionContext
export const useSection = (): SectionContextType => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error('useSection must be used within a SectionProvider');
  }
  return context;
};
