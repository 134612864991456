import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

type Props = {
  width?: string;
  height?: string;
  altText?: string;
};

const SmLogo = ({
  width = '6rem',
  height = '100%',
  altText = 'sm Logo',
}: Props) => {
  return (
    <div style={{ width, height }}>
      <StaticImage
        placeholder="blurred"
        src="../../../images/smLogo.svg"
        alt={altText}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default SmLogo;
