import React, { useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { Formik, Form, Field } from 'formik';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import axios from 'axios';
import CustomButton from '../buttons/CustomButton';
import { formIds } from '../../../utils/formIds';
import { FaSpinner } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';

type Prop = {
  open: boolean;
  setOpen: (e: boolean) => void;
};

const BookFreeProgram = ({ open, setOpen }: Prop) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    program: 'School/University',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    phone: Yup.string()
      .matches(/^(?:\+94|0)\d{9}$/, 'Invalid phone number format')
      .required('Phone number is required'),
    message: Yup.string(),
    program: Yup.string().required('Select is required'),
  });

  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <ToastContainer />
      <div className="absolute left-1/2 top-1/2 flex h-fit w-[80%] -translate-x-1/2 -translate-y-1/2 justify-center rounded-xl border border-primary-gray bg-white p-2 shadow-xl sm:w-[55%] md:w-fit">
        <div className="flex flex-col rounded-md border border-gray-600 px-20 py-12">
          <StaticImage
            src="../../../images/smLogo.svg"
            alt="Suwa Diviya"
            placeholder="blurred"
            className="mx-auto mb-2 h-[6rem] w-[6rem]"
          />
          <h1 className="text-center font-poppins text-[3.5rem] font-semibold leading-[4.2rem] text-primary-blue">
            Book A Free Program
          </h1>
          <p className="my-1 text-center font-poppins text-[1.6rem] font-semibold text-light-blue">
            Register Now!
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                setIsSubmitting(true);
                await axios.post(
                  `${process.env.GATSBY_BACKEND_URL}/submit` || '',
                  {
                    formId: formIds.FREE_PROGRAM_FORM,
                    responses: values,
                  }
                );
                toast.success('Message sent successfully!', {
                  autoClose: 2000,
                });
                resetForm();
                setTimeout(() => {
                  setOpen(false);
                }, 3000);
              } catch {
                toast.error('Something went wrong, please try again later..');
              }
              setIsSubmitting(false);
            }}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form className="mt-10 flex cursor-pointer flex-col gap-5">
                <TextField
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  name="name"
                  label="Name"
                  variant="standard"
                  value={values.name}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      style: { fontSize: '16px' },
                    },
                    inputLabel: {
                      style: { fontSize: '14px' },
                    },
                  }}
                />
                <TextField
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  name="email"
                  label="Email"
                  variant="standard"
                  value={values.email}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      style: { fontSize: '16px' },
                    },
                    inputLabel: {
                      style: { fontSize: '14px' },
                    },
                  }}
                />
                <TextField
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  name="phone"
                  label="Phone No"
                  variant="standard"
                  value={values.phone}
                  onChange={handleChange}
                  slotProps={{
                    input: {
                      style: { fontSize: '16px' },
                    },
                    inputLabel: {
                      style: { fontSize: '14px' },
                    },
                  }}
                />
                <FormControl
                  error={touched.program && Boolean(errors.program)}
                  variant="standard"
                  className="w-full"
                  sx={{
                    '& .MuiInputLabel-root': {
                      fontSize: '14px',
                    },
                    '& .MuiSelect-select': {
                      fontSize: '16px',
                    },
                    '& .MuiMenuItem-root': {
                      fontSize: '16px',
                    },
                  }}
                >
                  <InputLabel id="program-label">Program</InputLabel>
                  <Select
                    name="program"
                    labelId="program-label"
                    value={values.program}
                    onChange={handleChange}
                  >
                    <MenuItem value="School/University">
                      School/University
                    </MenuItem>
                    <MenuItem value="Corporate">Corporate Program</MenuItem>
                    <MenuItem value="Community">Community Program</MenuItem>
                    <MenuItem value="Support Group">Support Group</MenuItem>
                  </Select>
                  {touched.program && errors.program && (
                    <div className="error-message text-red-600">
                      {errors.program}
                    </div>
                  )}
                </FormControl>
                <TextField
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                  name="message"
                  label="Message (optional)"
                  multiline
                  rows={4}
                  value={values.message}
                  onChange={handleChange}
                  fullWidth
                  slotProps={{
                    input: {
                      style: { fontSize: '16px' },
                    },
                    inputLabel: {
                      style: { fontSize: '14px' },
                    },
                  }}
                />
                <CustomButton
                  type="submit"
                  height="4rem"
                  disabled={isSubmitting}
                  backgroundColor="#FAA61A"
                  color="white"
                  fontWeight="600"
                >
                  <p className="flex items-center justify-center text-[1.5rem]">
                    {isSubmitting ? (
                      <FaSpinner className="animate-spin" />
                    ) : (
                      'Register'
                    )}
                  </p>
                </CustomButton>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BookFreeProgram;
