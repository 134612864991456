import { navigate } from 'gatsby';
import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
};

const DropDown: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="relative flex w-fit items-center"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {/* Trigger */}
      <div className="select-trigger">{children}</div>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="select-options">
          <div
            className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
            onClick={() => {
              setIsOpen(false);
              navigate('/bmi');
            }}
          >
            BMI CALCULATOR
          </div>
          <div
            className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
            onClick={() => {
              setIsOpen(false);
              navigate('/bmr');
            }}
          >
            BMR CALCULATOR
          </div>
          <div
            className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
            onClick={() => {
              setIsOpen(false);
              navigate('/drf');
            }}
          >
            DIABETES RISK ASSESSMENT
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
